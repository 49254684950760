import axios from 'axios';
import uuid4 from 'uuid/v4';
import localforage from 'localforage';
import { whiteLabelConfig } from './constants/domain';
import moment from 'moment';

const addCommas = str => {
  var parts = (str + '').split('.'),
    main = parts[0],
    len = main.length,
    output = '',
    first = main.charAt(0),
    i;

  if (first === '-') {
    main = main.slice(1);
    len = main.length;
  } else {
    first = '';
  }
  i = len - 1;
  while (i >= 0) {
    output = main.charAt(i) + output;
    if ((len - i) % 3 === 0 && i > 0) {
      output = '.' + output;
    }
    --i;
  }
  // put sign back
  output = first + output;
  // put decimal part back
  if (parts.length > 1) {
    output += ',' + parts[1];
  }
  return output;
};

const Utils = {
  baseURL:
    process.env.REACT_APP_BASE_URL && process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_BASE_URL
      : `${window.location.protocol}//${window.location.hostname}`,

  requestHeaders: {},
  requests: {
    cancels: {},
  },
  token: null,

  copy: object => {
    return JSON.parse(JSON.stringify(object));
  },

  uniqueArray: array => {
    return array.filter((item, pos) => {
      return item && array.indexOf(item) === pos;
    });
  },

  unaccent: string => {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  },

  normalize: (type, array) => {
    if (type === 'state') {
      return [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO',
      ];
    } else {
      let newArray = Utils.copy(array);
      if (type === 'city') {
        newArray = newArray.map(item => {
          if (item) {
            item = item.split('(')[0].trim();
            item = item.split('/')[0].trim();
            item = Utils.normalizaString(item);
          }
          return item;
        });
      } else if (type === 'district') {
        newArray = newArray.map(item => {
          if (item) {
            item = item.split('/')[0].trim();
            item = Utils.normalizaString(item);
            item = item.split('BAIRRO');
            item = item[item.length - 1].trim();
          }
          return item;
        });
      } else if (type === 'property_type') {
        const commonTypes = [
          ['APT', 'APARTAMENTO'],
          ['APARTAMENTO', 'APARTAMENTO'],
          ['TERRENO', 'TERRENO'],
          ['TERRA', 'TERRENO'],
          ['CASA', 'CASA'],
          ['SOBRADO', 'CASA'],
          ['SOBRADO', 'CASA'],
          ['CHACARA', 'CASA'],
          ['SITIO', 'CASA'],
          ['GALPAO', 'GALPAO'],
          ['UNIDADE', 'IMOVEL'],
          ['IMOVEL', 'IMOVEL'],
          ['PREDIO', 'IMOVEL'],
          ['IMOVEIS', 'IMOVEL'],
          ['GALPAO', 'IMOVEL'],
          ['GALPOES', 'IMOVEL'],
          ['LOJA', 'IMOVEL'],
          ['VAGA', 'IMOVEL'],
          ['SALA', 'SALA'],
          ['GLEBA', 'GLEBA'],
          ['GLEBAS', 'GLEBA'],
          ['RURAL', 'RURAL'],
          ['RURAIS', 'RURAL'],
          ['COMERCIAL', 'COMERCIAL'],
          ['COMERCIAIS', 'COMERCIAL'],
          ['INDUSTRIAL', 'INDUSTRIAL'],
          ['INDUSTRIAIS', 'INDUSTRIAL'],
          ['COBERTURA', 'APARTAMENTO'],
        ];
        newArray = newArray.map(item => {
          item = Utils.normalizaString(item);
          let index = commonTypes.findIndex(commonType => {
            return commonType && item.indexOf(commonType[0]) !== -1;
          });
          if (index !== -1) {
            return commonTypes[index][1];
          }
          return item;
        });
      } else {
        newArray = newArray.map(item => item && Utils.normalizaString(item));
      }
      return Utils.uniqueArray(newArray).sort(Utils.compare);
    }
  },

  normalizaString: function(v) {
    return v && typeof v === 'string' ? Utils.unaccent(v.toUpperCase()) : null;
  },

  compare: function(b, a) {
    if (typeof a === 'number' && typeof b === 'number') {
      a = parseFloat(a);
      b = parseFloat(b);
      return b - a;
    }

    if (typeof a === 'string' || typeof b === 'string') {
      a = (a || '').toString();
      b = (b || '').toString();
      return b.localeCompare(a);
    }

    return b > a ? 1 : -1;
  },

  setAuthorizationToken: token => {
    localforage.setItem('token', token);
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      Utils.requestHeaders.Authorization = `Bearer ${token}`;
      Utils.token = token;
    }
  },

  auctionStatuses: {
    '2 praca': 'Em andamento',
    '1 praca': 'Em andamento',
    aberto: 'Em andamento',
    'em breve': 'Em breve',
  },

  formatters: {
    currencyNumber: value => `${addCommas(value ? Number(value) : 0)}`,
    currency: value => `R$ ${addCommas(value ? value.toFixed(2) : 0)}`,
    percentDiff: diff => `${Math.abs(diff).toFixed(1)}% ${diff < 0 ? ' abaixo' : 'acima'}`,
    cnpj: value =>
      value != null
        ? value
            .padStart(14, '0')
            .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5')
        : null,
    zipcode: value =>
      value != null ? value.padStart(8, '0').replace(/^(\d{5})(\d{3})?/, '$1-$2') : null,
    location: (district, city, state) => {
      if (district == null && city == null && state == null) {
        return 'Não especificado';
      }
      return (
        (district || '') +
        (district && city ? ',' : '') +
        (city || '') +
        (state && (city || district) ? ' -' : '') +
        (state || '')
      );
    },
  },

  request(options = {}) {
    if (options.url.startsWith('/')) {
      options.url = this.baseURL + options.url;
    }

    options.headers = {
      ...this.requestHeaders,
      ...(options.headers || {}),
    };

    // Check for false because undefined should be considered true
    if (options.jwt === false) {
      delete options.headers.Authorization;
    }

    const cancelId = uuid4();
    options.cancelToken =
      options.cancelToken ||
      new axios.CancelToken(function(c) {
        Utils.requests.cancels[cancelId] = c;
      });

    const request = axios
      .request(options)
      .then(r => {
        delete Utils.requests.cancels[cancelId];
        return r;
      })
      .catch(e => {
        delete Utils.requests.cancels[cancelId];
        throw e;
      });

    return request;
  },

  debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this,
        args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  },

  sorterTable: (key, type, extra) => (a, b) => {
    if (!Utils.sorters.hasOwnProperty(type)) {
      return;
    }
    let ta = a[key];
    let tb = b[key];
    return Utils.sorters[type](ta, tb, extra);
  },

  sorters: {
    date: (a, b, format) => Utils.sorters.moment(moment(a, format), moment(b, format)),
    moment: (a, b) => a.isBefore(b),
    string: (a, b) => a.localeCompare(b),
    number: (a, b) => a - b,
  },

  lang_: null,

  get lang() {
    if (Utils._lang != null) {
      return Utils._lang;
    }
    Utils._lang = navigator.language ? navigator.language : 'pt-BR';
    return Utils._lang;
  },

  getConfigWhiteLabel: () => {
    return (
      whiteLabelConfig[window.location.hostname] || {
        name: 'Realstate',
        title: 'Realstate',
        favicon: 'favicon.png',
        logo: 'logo.png',
        logo_alt: 'logo-alt.png',
      }
    );
  },

  unformatPhone: val => {
    return (
      '55' +
      val
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace('-', '')
        .replace(' ', '')
    );
  },

  useGlobalParameters: () => {
    const whiteList = ['buscas.catuaiasset.com.br', 'localhost', 'realstate.0soft.duckdns.org'];

    return Boolean(whiteList.find(it => it === window.location.hostname));
  },
};

export default Utils;
