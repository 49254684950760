export const comparative_status_label = {
  complete: 'Finalizada',
  crawling: 'Em progresso',
  error: 'Falhou',
};

export const sale = {
  property_types: [
    'APARTAMENTO',
    'BOX/GARAGEM',
    'CASA',
    'CASA DE CONDOMINIO',
    'CASA DE VILA',
    'CHACARA',
    'COBERTURA',
    'CONSULTORIO',
    'EDIFICIO RESIDENCIAL',
    'FAZENDA/SITIO',
    'FLAT',
    'GALPAO/DEPOSITO/ARMAZEM',
    'IMOVEL COMERCIAL',
    'KITNET/CONJUGADO',
    'LOFT',
    'LOJA',
    'LOTE/TERRENO RESIDENCIAL',
    'LOTE/TERRENO COMERCIAL',
    'PONTO COMERCIAL/LOJA/BOX',
    'PREDIO COMERCIAL',
    'SOBRADO',
    'SALA/CONJUNTO',
  ],
};

export const monitoring_status_label = {
  working: 'Funcionando',
  not_sure: 'Alguns dias sem novos leilões',
  stopped: 'Parado',
  error: 'Com erro',
};

export const whiteLabelConfig = {
  'buscas.catuaiasset.com.br': {
    name: 'Catuai',
    title: 'Catuai Leilões',
    favicon: 'catuai.ico',
    logo: 'logo-catuai-small.png',
    logo_alt: 'logo-icon-catuai-alt.png',
  },
};
