import * as types from '../constants/actionTypes';
import Utils from 'utils';

const initialState = {
  loading: false,
  auctions: {
    data: [],
    pageSize: 10,
    page: 1,
    total: 0,
    filters: {
      search: '',
      order_by: 'latest',
    },
  },
  favorites: {
    data: [],
    pageSize: 10,
    page: 1,
    total: 0,
    filters: {
      search: '',
      order_by: 'latest',
    },
  },
  // auction_types: [],
  property_types: [],
  statuses: [],
  comitents: [],
  addresses: [],
  origins: [],
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_AUCTIONS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_AUCTIONS_SUCCESS:
      // let auction_types = Utils.normalize('auction_type', action.data.auction_types);
      // let property_types = Utils.normalize('property_type', action.data.property_types);
      // let statuses = Utils.normalize('status', action.data.statuses);
      // let comitents = Utils.normalize('comitent', action.data.comitents);
      // let origins = Utils.normalize('origins', action.data.origins);

      // comitents = comitents.filter(
      //   it => it.toLowerCase().split(" ").reduce(
      //     (acc, word) => acc && word !== "vara" && word !== "forum" && word !== "justica" && word !== "civel", true
      //   )
      // );

      return {
        ...state,
        loading: false,
        [action.key]: {
          ...state[action.key],
          data: action.data.auctions,
          total: action.data.total,
        },
        // auction_types,
        // property_types,
        // statuses,
        // comitents,
        // addresses: action.data.addresses,
        // origins,
      };
    case types.GET_AUCTIONS_REJECTED:
      return {
        ...initialState,
      };
    case types.FAVORITE_AUCTION_SUCCESS:
      let auctions = state[action.keys].data;
      let favoritedAuction = auctions.findIndex(it => it.id === action.auction.id);
      auctions[favoritedAuction].is_favorite = action.favorite;
      return {
        ...state,
        loading: false,
        [action.key]: {
          ...state[action.key],
          data: auctions
        },
      };
    case types.FAVORITE_AUCTION_REJECTED:
      return {
        ...state,
        loading: false,
      };
    case types.AUCTION_CHANGE_ORDER:
      return {
        ...state,
        loading: false,
        [action.key]: {
          ...state[action.key],
          filters: {...state[action.key].filters, order_by: action.order}
        },
      };
    case types.AUCTION_SET_FILTERS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          filters: {...state[action.key].filters, ...action.filters}
        },
      };
    case types.AUCTION_CHANGE_PAGE:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          page: action.page,
          pageSize: action.pageSize,
        }
      }
    case types.AUCTION_RESET_FILTERS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          filters: initialState[action.key].filters
        },
      }
    case types.AUCTION_DOMAIN_SUCCESS:
      // const auction_types = Utils.normalize('auction_type', action.data.auction_types);
      const property_types = Utils.normalize('property_type', action.data.property_types);
      const statuses = Utils.normalize('status', action.data.statuses);
      let comitents = Utils.normalize('comitent', action.data.comitents);
      const origins = Utils.normalize('origins', action.data.origins);

      comitents = comitents.filter(
        it => it.toLowerCase().split(" ").reduce(
          (acc, word) => acc && word !== "vara" && word !== "forum" && word !== "justica" && word !== "civel", true
        )
      );
      return {
        ...state,
        // auction_types,
        property_types,
        statuses,
        comitents,
        addresses: action.data.addresses,
        origins,
      };
    case types.AUCTION_DOMAIN_REJECTED:
      return {
        ...state,
        domain_error: action.error
      };
    default:
      return state;
  }
}

export default settings;
