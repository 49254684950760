export const GET_ADDRESS = 'GET_ADDRESS';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const TOGGLE_BOXED_LAYOUT = 'TOGGLE_BOXED_LAYOUT';
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const TOGGLE_OFFCANVAS_NAV = 'TOGGLE_OFFCANVAS_NAV';
export const TOGGLE_FIXED_SIDENAV = 'TOGGLE_FIXED_SIDENAV';
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER';
export const CHANGE_SIDENAV_WIDTH = 'CHANGE_SIDENAV_WIDTH';
export const TOGGLE_OFFCANVAS_MOBILE_NAV = 'TOGGLE_OFFCANVAS_MOBILE_NAV';
export const CHANGE_COLOR_OPTION = 'CHANGE_COLOR_OPTION';
export const CHANGE_THEME = 'CHANGE_THEME';
export const GET_AUCTIONS = 'GET_AUCTIONS';
export const GET_AUCTIONS_SUCCESS = 'GET_AUCTIONS_SUCCESS';
export const GET_AUCTIONS_PENDING = 'GET_AUCTIONS_PENDING';
export const GET_AUCTIONS_REJECTED = 'GET_AUCTIONS_REJECTED';
export const AUCTION_SEARCH_KEYWORD = 'AUCTION_SEARCH_KEYWORD';
export const AUCTION_SET_FILTERS = 'AUCTION_SET_FILTERS';
export const AUCTION_RESET_FILTERS = 'AUCTION_RESET_FILTERS';
export const AUCTION_CHANGE_PAGE = 'AUCTION_CHANGE_PAGE';
export const AUCTION_CHANGE_ORDER = 'AUCTION_CHANGE_ORDER';
export const AUCTION_DOMAIN_SUCCESS = 'AUCTION_DOMAIN_SUCCESS';
export const AUCTION_DOMAIN_REJECTED = 'AUCTION_DOMAIN_REJECTED';
export const UNFAVORITE_AUCTION_SUCCESS = 'UNFAVORITE_AUCTION_SUCCESS';
export const UNFAVORITE_AUCTION_REJECTED = 'UNFAVORITE_AUCTION_REJECTED';
export const FAVORITE_AUCTION_SUCCESS = 'FAVORITE_AUCTION_SUCCESS';
export const FAVORITE_AUCTION_REJECTED = 'FAVORITE_AUCTION_REJECTED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_REJECTED = 'AUTH_LOGIN_REJECTED';
export const NOTIFICATION_READ_PENDING = 'NOTIFICATION_READ_PENDING';
export const NOTIFICATION_READ_SUCCESS = 'NOTIFICATION_READ_SUCCESS';
export const NOTIFICATIONS_CHANGE_PAGE = 'NOTIFICATIONS_CHANGE_PAGE';
export const CHECK_NOTIFICATIONS_SUCCESS = 'CHECK_NOTIFICATIONS_SUCCESS';
export const CHECK_NOTIFICATIONS_PENDING = 'CHECK_NOTIFICATIONS_PENDING';
export const CHECK_NOTIFICATIONS_REJECTED = 'CHECK_NOTIFICATIONS_REJECTED';
export const TOKEN_VERIFIED = 'TOKEN_VERIFIED';
export const VERIFY_TOKEN_REJECTED = 'VERIFY_TOKEN_REJECTED';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_REJECTED = 'REGISTER_USER_REJECTED';
export const CHANGE_PWD_SUCCESS = 'CHANGE_PWD_SUCCESS';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const OPEN_MODAL_SIGN_UP = 'OPEN_MODAL_SIGN_UP';
export const CLOSE_MODAL_SIGN_UP = 'CLOSE_MODAL_SIGN_UP';
export const SAVE_SETTINGS_PENDING = 'SAVE_SETTINGS_PENDING';
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_REJECTED = 'SAVE_SETTINGS_REJECTED';
export const LOAD_SETTINGS_PENDING = 'LOAD_SETTINGS_PENDING';
export const LOAD_SETTINGS_SUCCESS = 'LOAD_SETTINGS_SUCCESS';
export const LOAD_SETTINGS_REJECTED = 'LOAD_SETTINGS_REJECTED';
export const CHANGE_RECEIVE_EMAIL = 'CHANGE_RECEIVE_EMAIL';
export const CHANGE_CRAWLER_MIN_VALUE = 'CHANGE_CRAWLER_MIN_VALUE';
export const CHANGE_NOTIFICATION_DAYS_LEFT = 'CHANGE_NOTIFICATION_DAYS_LEFT';
export const MARKET_VALUE_PENDING = 'MARKET_VALUE_PENDING';
export const MARKET_VALUE_SUCCESS = 'MARKET_VALUE_SUCCESS';
export const MARKET_VALUE_REJECTED = 'MARKET_VALUE_REJECTED';
export const MARKET_RANGE_CHANGE = 'MARKET_RANGE_CHANGE';
export const SITE_INFO_PENDING = 'SITE_INFO_PENDING';
export const SITE_INFO_SUCCESS = 'SITE_INFO_SUCCESS';
export const SITE_INFO_REJECTED = 'SITE_INFO_REJECTED';
export const GET_COMPARATIVE_PENDING = 'GET_COMPARATIVE_PENDING';
export const GET_COMPARATIVE_SUCCESS = 'GET_COMPARATIVE_SUCCESS';
export const GET_COMPARATIVE_REJECTED = 'GET_COMPARATIVE_REJECTED ';
export const GET_COMPARATIVE_HISTORY_PENDING = 'GET_COMPARATIVE_HISTORY_PENDING';
export const GET_COMPARATIVE_HISTORY_SUCCESS = 'GET_COMPARATIVE_HISTORY_SUCCESS';
export const GET_COMPARATIVE_HISTORY_REJECTED = 'GET_COMPARATIVE_HISTORY_REJECTED';
export const POST_COMPARATIVE_SUCCESS = 'POST_COMPARATIVE_SUCCESS';
export const COMPARATIVE_SET_FILTERS = 'COMPARATIVE_SET_FILTERS';
export const COMPARATIVE_CHANGE_PAGE = 'COMPARATIVE_CHANGE_PAGE';
export const COMPARATIVE_RESET_FILTERS = 'COMPARATIVE_RESET_FILTERS';
export const GET_AUCTION_PENDING = 'GET_AUCTION_PENDING';
export const GET_AUCTION_SUCCESS = 'GET_AUCTION_SUCCESS';
export const GET_AUCTION_REJECT = 'GET_AUCTION_REJECT';
export const GET_CRAWLER_PENDING = 'GET_CRAWLER_PENDING';
export const GET_CRAWLER_SUCCESS = 'GET_CRAWLER_SUCCESS';
export const GET_CRAWLER_REJECT = 'GET_CRAWLER_REJECT';
export const CHANGE_PARAM = 'CHANGE_PARAM';
export const GET_DATA_PENDING = 'GET_DATA_PENDING';
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
export const GET_DATA_REJECT = 'GET_DATA_REJECT';
export const GET_DOMAIN_SUCCESS = 'GET_DOMAIN_SUCCESS';
export const GET_DOMAIN_REJECTED = 'GET_DOMAIN_REJECTED';
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CRECISP_SESSION = 'CRECISP_SESSION';
export const CRECISP_LOADING_SESSION = 'CRECISP_LOADING_SESSION';
export const CRECISP_FULFILLED_SESSION = 'CRECISP_FULFILLED_SESSION';
export const CRECISP_RUN_PENDING = 'CRECISP_RUN_PENDING';
export const CRECISP_RUN_SUCCESS = 'CRECISP_RUN_SUCCESS';
export const CRECISP_RUN_REJECTED = 'CRECISP_RUN_REJECTED';
