import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import loadable from 'react-loadable';
import LoadingComponent from 'components/Loading';
import { push } from 'react-router-redux';
import { authenticateUser } from 'actions/authActions';
import moment from 'moment';
import Utils from '../utils';
import 'moment/locale/pt-br';

// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';
import 'styles/vendors.scss';
import 'styles/custom.scss';

moment.locale(Utils.lang.toLowerCase());

let AsyncAppLayout = loadable({
  loader: () => import('components/Layout/AppLayout/'),
  loading: LoadingComponent,
});
let AsyncLogin = loadable({
  loader: () => import('routes/login/'),
  loading: LoadingComponent,
});

class App extends React.Component {
  state = { token: null };

  async checkAuthentication() {
    let token = await this.props.authenticateUser();
    this.setState({ token });
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) {
      this.setState({ token: this.props.token });
    }
  }

  render() {
    const { match, location } = this.props;
    const isRoot = location.pathname === '/' ? true : false;

    if (!this.props.isAuthenticated && location.pathname !== '/login') {
      return <Redirect to={'/login'} />;
    }

    if (isRoot) {
      return <Redirect to={'/app/auctions'} />;
    }

    return (
      <div id="app">
        {this.state.token != null && <Route path={`${match.url}app`} component={AsyncAppLayout} />}
        <Route path={`${match.url}login`} component={AsyncLogin} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.authToken,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  changeRoute: url => dispatch(push(url)),
  authenticateUser: () => dispatch(authenticateUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
