import * as types from '../constants/actionTypes';

const initialState = {
  loading: false,
  extra: {
    crecisp_session: {},
    crecisp_run_status: true,
  },
  notification: {
    receive_email: false,
    days_left: 0,
  },
  crawler: {
    min_value: 0,
  },
  crecisp: {
    is_active: false,
  },
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_RECEIVE_EMAIL:
      let receive = action.value;
      return {
        ...state,
        notification: {
          ...state.notification,
          receive_email: receive,
        },
      };
    case types.CHANGE_NOTIFICATION_DAYS_LEFT:
      let days = action.value;
      return {
        ...state,
        notification: {
          ...state.notification,
          days_left: days,
        },
      };
    case types.CHANGE_CRAWLER_MIN_VALUE:
      let value = action.value;
      return {
        ...state,
        crawler: {
          ...state.crawler,
          min_value: Number(value),
        },
      };
    case types.LOAD_SETTINGS_PENDING:
      return {
        ...initialState,
        loading: true,
      };
    case types.LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.data.parameters,
        loading: false,
      };
    case types.SAVE_SETTINGS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.SAVE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.CHANGE_PARAM:
      return {
        ...state,
        [action.k]: action.v,
      };
    case types.CRECISP_SESSION:
      return {
        ...state,
        extra: {
          ...state.extra,
          crecisp_session: {
            ...state.extra.crecisp_session,
            [action.key]: action.data,
          },
        },
      };
    case types.CRECISP_LOADING_SESSION:
      return {
        ...state,
        extra: {
          ...state.extra,
          crecisp_session: {
            ...state.extra.crecisp_session,
            [action.key]: {
              ...state.extra.crecisp_session[action.key],
              loading: true,
            },
          },
        },
      };
    case types.CRECISP_FULFILLED_SESSION:
      return {
        ...state,
        extra: {
          ...state.extra,
          crecisp_session: {
            ...state.extra.crecisp_session,
            [action.key]: {
              ...state.extra.crecisp_session[action.key],
              loading: false,
            },
          },
        },
      };
    case types.CRECISP_RUN_PENDING:
      return {
        ...state,
        extra: {
          ...state.extra,
          crecisp_run_success: null,
        },
      };
    case types.CRECISP_RUN_SUCCESS:
      return {
        ...state,
        extra: {
          ...state.extra,
          crecisp_run_success: true,
        },
      };
    case types.CRECISP_RUN_REJECTED:
      return {
        ...state,
        extra: {
          ...state.extra,
          crecisp_run_success: false,
        },
      };
    default:
      return state;
  }
};

export default settings;
