import * as types from '../constants/actionTypes';

const initialState = {
  loading: false,
  total_count: 0,
  last_update: null,
  last_update_count: 0
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.SITE_INFO_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.SITE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.data,
      };
    case types.SITE_INFO_REJECTED:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default settings;
