import * as types from '../constants/actionTypes';
const initialState = {
  loading: {},
  error: {},
  data: {},
};

export default (state = initialState, action) => {
  const type = types.GET_ADDRESS;
  switch (action.type) {
    case `${type}_PENDING`:
      state = {
        ...state,
        loading: {
          ...state.loading,
          [action.key]: true,
        },
      };
      break;
    case `${type}_FULFILLED`:
      let data = { ...state.data };
      let subtree = data;
      action.keys.forEach((k, i, list) => {
        const subitem = i !== list.length - 1 ? {} : action.payload;
        subtree[k] = subitem;
        subtree = subtree[k];
      });

      state = {
        ...state,
        loading: {
          ...state.loading,
          [action.keys[0]]: false,
        },
        error: {
          ...state.loading,
          [action.keys[0]]: false,
        },
        data: {
          ...data,
        },
      };
      break;
    case `${type}_REJECTED`:
      state = {
        ...state,
        loading: {
          ...state.loading,
          [action.key]: false,
        },
        error: {
          ...state.loading,
          [action.key]: true,
        },
      };
      break;
    default:
      break;
  }
  return state;
};
