import * as types from '../constants/actionTypes';

const initialState = {
  loading: false,
  market_value: null,
  filter_range: 10000,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.MARKET_VALUE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.MARKET_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        filter_range: action.filter_range,
        market_value: action.data,
      };
    case types.MARKET_VALUE_REJECTED:
      return {
        ...initialState,
      };
    case types.MARKET_RANGE_CHANGE:
      return {
        ...state,
        filter_range: action.filter_range,
      }
    default:
      return state;
  }
}

export default settings;
