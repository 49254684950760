import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import createHistory from 'history/createHashHistory';
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { routerMiddleware } from 'react-router-redux';
import rootReducer from '../reducers';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createRavenMiddleware from 'raven-for-redux';
export const history = createHistory();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

if (process.env.NODE_ENV === 'production') {
  if (window.hasOwnProperty('Raven') && window.Raven && window.Raven.hasOwnProperty('config')) {
    window.Raven.config(
      'https://3a1155af41ee42a780420df036c6611e@sentry.zerosoft.com.br/8'
    ).install();
  }
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStoreProd(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware,
    createRavenMiddleware(window.Raven, {
      stateTransformer: state => ({
        ...state,
        dashboard: null,
        notification: {
          ...state.notification,
          all: null,
          unread: null,
        },
      }),
    }),
  ];

  return createStore(persistedReducer, initialState, compose(applyMiddleware(...middlewares)));
}

function configureStoreDev(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware,
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return store;
}

const configureStore =
  process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
