import * as types from '../constants/actionTypes';

const initialState = {
  loading: false,
  crawlers: [],
};

const monitoring = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CRAWLER_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_CRAWLER_SUCCESS:
      return {
        ...state,
        loading: false,
        crawlers: action.data,
      };
    case types.GET_CRAWLER_REJECT:
      return {
        ...state,
        loading: false,
        crawlers: [],
      };
    default:
      return state;
  }
};

export default monitoring;
