import * as types from '../constants/actionTypes';

const initialState = {
  isAuthenticated: false,
  authToken: undefined,
  errorMsg: '',
  user: {
    username: '',
  },
  signUp: {
    modal: false,
    error: '',
  }
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.TOKEN_VERIFIED:
      return {
        ...state,
        isAuthenticated: true,
        authToken: action.data.token,
        errorMsg: '',
      };
    case types.VERIFY_TOKEN_REJECTED:
      return initialState;
    case types.AUTH_LOGIN_REJECTED:
      return {
        ...initialState,
        errorMsg: action.error,
      };
    case types.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        authToken: action.data.token,
        errorMsg: '',
      };
    case types.AUTH_LOGOUT:
      return initialState;
    case types.LOAD_USER_SUCCESS:
      return {
        ...state,
        user: action.data.user,
      };
    case types.CHANGE_PWD_SUCCESS:
      return {
        ...state,
      };
    case types.REGISTER_USER_SUCCESS:
      return {
        ...state,
        signUp: {
          error: "",
        }
      };
    case types.REGISTER_USER_REJECTED:
      return {
        ...state,
        signUp: {
          error: action.error,
          modal: true,
        }
      };
    case types.OPEN_MODAL_SIGN_UP:
      return {
        ...state,
        signUp: {
          modal: true,
          error: '',
        }
      };
    case types.CLOSE_MODAL_SIGN_UP:
      return {
        ...state,
        signUp: {
          modal: false,
        }
      };
    default:
      return state;
  }
}

export default settings;
