import * as types from '../constants/actionTypes';

const initialState = {
  loading: false,
  sales: [],
  task: null,
  selectedTask: null,
  history: [],
  pageSize: 10,
  page: 1,
  total: 0,
  filters: {
    search: '',
  },
  error: null,
  status: null,
  auction: null,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COMPARATIVE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_COMPARATIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedTask: null,
        ...action.data,
      };
    case types.GET_COMPARATIVE_REJECTED:
      return {
        ...initialState,
        loading: false,
        error: action.data.error,
      };
    case types.GET_COMPARATIVE_HISTORY_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_COMPARATIVE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: action.data,
      };
    case types.GET_COMPARATIVE_HISTORY_REJECTED:
      return {
        ...initialState,
      };
    case types.POST_COMPARATIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        auction: null,
        selectedTask: action.data.hash,
        error: action.data.error,
        status: action.data.status,
      };
    case types.COMPARATIVE_SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.filters },
      };
    case types.COMPARATIVE_CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
        pageSize: action.pageSize,
      };
    case types.COMPARATIVE_RESET_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
      };
    case types.GET_AUCTION_PENDING:
      return {
        ...state,
        loading: true,
        auction_id: null,
      };
    case types.GET_AUCTION_SUCCESS:
      return {
        ...state,
        auction: action.data,
        loading: false,
      };
    case types.GET_AUCTION_REJECT:
      return {
        ...state,
        error: action.data.error,
      };
    default:
      return state;
  }
};

export default settings;
