import * as types from '../constants/actionTypes';
import Utils from 'utils';
import { push } from 'react-router-redux';
import localforage from 'localforage';
import { notification } from 'antd';

export function login(values) {
  return async (dispatch, getState) => {
    const data = {
      username: values.username,
      password: values.password,
    };
    dispatch({ type: types.AUTH_LOGIN });
    await Utils.request({
      url: '/api/auth/login',
      method: 'POST',
      data,
    })
      .then(response => {
        Utils.setAuthorizationToken(response.data.token);
        dispatch({ type: types.AUTH_LOGIN_SUCCESS, data: response.data });
      })
      .catch(error => {
        let msg = error.response ? error.response.data.error : 'Servidor indisponível';
        dispatch({ type: 'AUTH_LOGIN_REJECTED', error: msg });
      });
  };
}

export function logout() {
  return async (dispatch, getState) => {
    try {
      localforage.clear();
      dispatch({ type: types.AUTH_LOGOUT });
    } catch (e) {
      dispatch({ type: 'AUTH_LOGOUT_REJECTED' });
    }
  };
}

export function authenticateUser() {
  return async (dispatch, getState) => {
    let token = await localforage.getItem('token');

    if (token == null) {
      dispatch({ type: 'TOKEN_NOT_PRESENT' });
      dispatch(push('/login'));
    }

    let data = {};
    try {
      data = await Utils.request({
        url: '/api/auth/verify_token',
        method: 'POST',
        data: {
          token: token,
        },
      }).then(res => res.data);

      Utils.setAuthorizationToken(data.token);
      dispatch({ type: types.TOKEN_VERIFIED, data });
    } catch (e) {
      dispatch({ type: 'VERIFY_TOKEN_REJECTED' });
      dispatch(push('/login'));
    }

    return data.token;
  };
}

export function loadUser() {
  return async (dispatch, getState) => {
    let token = localforage.getItem('token');

    token
      .then(token => {
        Utils.request({
          url: '/api/auth/load_user',
          method: 'POST',
          data: {
            token: token,
          },
        })
          .then(response => {
            dispatch({ type: types.LOAD_USER_SUCCESS, data: response.data });
          })
          .catch(err => {
            dispatch({ type: 'LOAD_USER_REJECTED' });
          });
      })
      .catch(err => {
        dispatch({ type: 'LOAD_USER_REJECTED' });
      });
  };
}

export function openModalSignUp(form) {
  return async (dispatch, getState) => {
    dispatch({ type: types.OPEN_MODAL_SIGN_UP });
  };
}

export function closeModalSignUp(form) {
  return async (dispatch, getState) => {
    dispatch({ type: types.CLOSE_MODAL_SIGN_UP });
  };
}

export function registerUser(form) {
  return async (dispatch, getState) => {
    let token = localforage.getItem('token');

    if (!form.username) {
      dispatch({ type: 'REGISTER_USER_REJECTED', error: 'Insira um usuário!' });
      notification.open({
        message: 'Erro!',
        description: 'Ocorreu um erro inesperado!',
      });
    } else if (form.password !== form.confirmPassword) {
      dispatch({ type: 'REGISTER_USER_REJECTED', error: 'As senhas não conferem!' });
      notification.open({
        message: 'Erro!',
        description: 'Ocorreu um erro inesperado!',
      });
    } else {
      token.then(token => {
        Utils.request({
          url: '/api/auth/register_user',
          method: 'POST',
          data: {
            token: token,
            form: form,
          },
        })
          .then(response => {
            dispatch({ type: types.REGISTER_USER_SUCCESS });
            notification.open({
              message: 'Sucesso',
              description: 'Usuário criado com sucesso!',
            });
          })
          .catch(err => {
            let response = err.response;
            let msg = response ? response.data.error : 'Desconhecido';
            dispatch({ type: 'REGISTER_USER_REJECTED', error: msg });
            notification.open({
              message: 'Erro!',
              description: 'Ocorreu um erro inesperado!',
            });
          });
      });
    }
  };
}

export function setEmail(email) {
  return async (dispatch, getState) => {
    let token = localforage.getItem('token');

    token
      .then(token => {
        Utils.request({
          url: '/api/auth/change_email',
          method: 'POST',
          data: {
            token: token,
            email: email,
          },
        })
          .then(response => {
            dispatch({ type: types.CHANGE_EMAIL_SUCCESS });
          })
          .catch(err => {
            dispatch({ type: 'CHANGE_EMAIL_REJECTED' });
          });
      })
      .catch(err => {
        dispatch({ type: 'CHANGE_EMAIL_REJECTED' });
      });
  };
}
export function setPassword(pwd) {
  return async (dispatch, getState) => {
    let token = localforage.getItem('token');

    token
      .then(token => {
        Utils.request({
          url: '/api/auth/change_pwd',
          method: 'POST',
          data: {
            token: token,
            pwd: pwd,
          },
        })
          .then(response => {
            dispatch({ type: types.CHANGE_PWD_SUCCESS });
          })
          .catch(err => {
            dispatch({ type: 'CHANGE_PWD_REJECTED' });
          });
      })
      .catch(err => {
        dispatch({ type: 'CHANGE_PWD_REJECTED' });
      });
  };
}
