import * as types from '../constants/actionTypes';

const initialState = {};
const emptyKey = {
  loading: false,
  data: [],
  error: false,
  page: 1,
  total: 0,
  domain: {},
  domain_error: false,
  filters: {
    search: '',
    order_by: 'latest',
  },
};

const filterPag = (state = initialState, action) => {
  if (action.key != null && !state.hasOwnProperty(action.key)) {
    state = {
      ...state,
      [action.key]: emptyKey,
    };
  }
  switch (action.type) {
    case types.GET_DATA_PENDING:
      let newstate = {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: true,
        },
      };
      return newstate;
    case types.GET_DATA_REJECT:
      return {
        ...state,
        [action.key]: {
          ...emptyKey,
        },
      };
    case types.GET_DATA_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          data: action.data.results,
          total: action.data.total,
          loading: false,
        },
      };
    case types.GET_DOMAIN_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          domain: {
            ...state[action.key].domain,
            ...action.data.domain,
          },
        },
      };
    case types.GET_DOMAIN_REJECTED:
      return {
        ...state,
        [action.key]: {
          domain_error: action.data.error,
          domain: {},
        },
      };
    case types.CHANGE_PAGE:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          page: action.page,
          pageSize: action.pageSize,
        },
      };
    case types.SET_FILTERS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          filters: action.filters,
        },
      };
    case types.RESET_FILTERS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          filters: emptyKey.filters,
        },
      };
    default:
      return state;
  }
};

export default filterPag;
