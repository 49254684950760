import * as types from '../constants/actionTypes';

const initialState = {
  all: [],
  unread: [],
  total: 0,
  total_unread: 0,
  loading: false,
  page: 1,
  pageSize: 10,
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_NOTIFICATIONS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.CHECK_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        all: action.data.all_items,
        unread: action.data.unread,
        total: action.data.total_items,
        total_unread: action.data.total_unread,
      };
    case types.NOTIFICATION_READ_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.NOTIFICATION_READ_SUCCESS:
      let list = state.all.slice();
      let index = list.findIndex(item => {return item.notification_id === action.data.id});
      list[index].is_read = true;
      return {
        ...state,
        all: list,
        total: state.total,
        total_unread: state.total_unread - 1,
        loading: false,
      };
    case types.NOTIFICATIONS_CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
        pageSize: action.pageSize,
      };
    default:
      return state;
  }
}

export default notification;
