import { combineReducers } from 'redux';
import address from './address';
import settingsReducer from './settingsReducer';
import dashboardReducer from './dashboardReducer';
import authReducer from './authReducer';
import notificationReducer from './notificationReducer';
import configReducer from './configReducer';
import marketValueReducer from './marketValueReducer';
import infoReducer from './infoReducer';
import comparativeReducer from './comparative';
import monitoring from './monitoringReducer';
import filterPag from './filterPagReducer';
import { routerReducer } from 'react-router-redux';

const rootReducer = combineReducers({
  address,
  settings: settingsReducer,
  routing: routerReducer,
  dashboard: dashboardReducer,
  auth: authReducer,
  notification: notificationReducer,
  config: configReducer,
  marketValue: marketValueReducer,
  siteInfo: infoReducer,
  comparative: comparativeReducer,
  monitoring,
  pages: filterPag,
});

export default rootReducer;
